import GuidUtils from '@/shared/utils/guid'

export default class Anamnese {
  constructor({
    id,
    companyId,
    name,
    mnemonic,
  } = {}) {
    this.id = id || GuidUtils.emptyGuid,
    this.companyId = companyId,
    this.name = name,
    this.mnemonic = mnemonic
  }
}