import AnamneseRepository from '@/shared/http/repositories/socialProject/anamnese'
import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import NewAnamnese from '../components/anamnese.vue'
import Anamnese from '@/shared/models/anamnese'
import { IsSuccessCode } from '@/shared/utils/API'
import { loading, toast } from '@/shared/utils/UIHelper'

export default {
  name: 'EditarAnamnese',

  components: {
    [BreadCrumb.name]: BreadCrumb,
    [NewAnamnese.name]: NewAnamnese
  },

  data: () => ({
    placeholder: 'Nome',
    title: 'Grupo de Anamnese',
    anamnese: new Anamnese(),
    isNew: false
  }),

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'Grupos de anamnese',
            href: this.$router.resolve({ name: 'Anamnese' }).href
          },
          {
            text: 'Editar grupo de anamnese',
            active: true
          }
        ]
      }
    }
  },

  created() {
    this.getById()
  },

  methods: {
    getById() {
      loading.push()
      AnamneseRepository.GetById(this.$route.params.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.anamnese = res.data.data
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar grupo de anamnese', 'ERRO')
        })
    },

    update(anamnese) {
      loading.push()
      AnamneseRepository.Update(anamnese)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          loading.pop()
          toast.success('Grupo de anamnese editado com sucesso', 'EDITAR ANAMNESE')
          Promise.resolve()
          return this.$router.replace({ name: 'Anamnese' })
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao editar grupo de anamnese', 'ERRO')
        })
    }
  }
}
